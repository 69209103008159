import React from 'react'
import classNames from 'utils/classnames'

type Props = {
  isSelected: boolean
  onClick?: () => void
  variant: 'allowlist' | 'public'
}

const variantText: Record<Props['variant'], string> = {
  allowlist: 'Allowlist',
  public: 'Public mint',
}

function TransactionSectionToggle({ isSelected, onClick, variant }: Props) {
  return (
    <button
      className={classNames(
        'flex items-center justify-center gap-x-2',
        'h-8 px-3',
        'text-white text-sm font-bold',
        'rounded-md',
        'bg-black dark:bg-neutral-600',
        isSelected ? 'dark:bg-opacity-80' : 'bg-opacity-60 dark:bg-opacity-40',
        variant === 'allowlist' && 'al-btn-sm',
        // z-0 needed for the modal state since the allowlist background
        // appears above it otherwise
        'z-0',
      )}
      onClick={onClick}
    >
      <div
        className={classNames(
          'rounded-full w-2 h-2',
          'bg-white',
          !isSelected && 'bg-opacity-50 dark:bg-opacity-30',
        )}
      />
      {variantText[variant]}
    </button>
  )
}

export default React.memo(TransactionSectionToggle)
