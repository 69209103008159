import { SVGProps } from 'react'

export default function FlagFilled(props: Partial<SVGProps<SVGSVGElement>>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M10.425 3a.675.675 0 00-.675.675.45.45 0 01-.45.45H5.812a.563.563 0 01-.562-.563.563.563 0 00-1.125 0v.113a.45.45 0 01-.45.45A.675.675 0 003 4.8v14.4c0 .373.302.675.675.675a.45.45 0 01.45.45v.113a.562.562 0 101.125 0v-.113a.45.45 0 01.45-.45.675.675 0 00.675-.675v-5.4c0-.373.302-.675.675-.675H9.3a.45.45 0 01.45.45c0 .373.302.675.675.675h2.025a.675.675 0 00.675-.675.45.45 0 01.45-.45h2.25a.675.675 0 00.675-.675.45.45 0 01.45-.45h1.238c.31 0 .562.252.562.563 0 .31.252.562.563.562.31 0 .562.252.562.563a.562.562 0 101.125 0V5.925a.675.675 0 00-.675-.675.45.45 0 01-.45-.45.675.675 0 00-.675-.675.45.45 0 01-.45-.45.675.675 0 00-.675-.675h-.9a.675.675 0 00-.675.675.45.45 0 01-.45.45h-2.475a.45.45 0 01-.45-.45A.675.675 0 0012.45 3h-2.025z"
      ></path>
    </svg>
  )
}
