import { FunctionComponent, useMemo } from 'react'
import useResizeObserver from 'use-resize-observer'
import { CardRow } from 'components/NFTCarousel/CardRow'
import NFTView, { Props as NFTViewProps } from 'components/NFTView'
import { SampleNFTsResponse } from 'utils/api'
import classnames from 'utils/classnames'

type Props = {
  className?: string
  nftsResponse?: SampleNFTsResponse
  CardElement?: FunctionComponent<NFTViewProps>
  rowIndex?: number
  setWidth?: (width: number) => void
  size?: 'mobile' | 'tablet' | 'desktop'
}

export default function NFTCarousel({
  className,
  nftsResponse,
  CardElement = NFTView,
  rowIndex,
  size = 'desktop',
}: Props) {
  const numNftsToDisplay = size === 'mobile' ? 4 : size === 'tablet' ? 5 : 7
  const wrapperSize = useResizeObserver<HTMLElement>()

  const nftsToDisplay = useMemo(
    () => nftsResponse?.recent.slice(0, numNftsToDisplay) ?? [],
    [numNftsToDisplay, nftsResponse],
  )

  return (
    <div
      className={classnames(
        'w-full grid grid-cols-4 md:max-lg:grid-cols-5 md:grid-cols-7 gap-1 md:gap-3',
        size === 'mobile' && 'md:hidden',
        size === 'tablet' && 'hidden md:max-lg:grid',
        size === 'desktop' && 'hidden lg:grid',
        className,
      )}
      ref={wrapperSize.ref}
    >
      {nftsResponse !== undefined && nftsToDisplay.length === 0 && (
        <div className="flex self-center justify-center w-full">
          No NFTs minted yet
        </div>
      )}
      {nftsResponse !== undefined && nftsToDisplay.length > 0 && (
        <CardRow
          key={wrapperSize.width}
          numCards={numNftsToDisplay}
          cards={nftsToDisplay.map((nft) => ({
            blockNumber: nft.blockNumber.toString(),
            logIndex: nft.logIndex,
            element: (
              <CardElement
                nft={nft}
                profiles={nftsResponse?.profiles}
                loading={rowIndex === 0 || rowIndex === 1 ? 'eager' : 'lazy'}
              />
            ),
          }))}
        />
      )}
    </div>
  )
}
