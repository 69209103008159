import React, { useMemo } from 'react'
import HoverTimestamp from 'components/HoverTimestamp'
import { MintStatus } from 'utils/api'
import classnames from 'utils/classnames'

type Props = {
  mintStatus: MintStatus
  mintedOutTime?: string
}

const mintStatusColor: Record<MintStatus, React.CSSProperties> = {
  notAvailable: { backgroundColor: '#fbff48' },
  noMints: {},
  mintingNow: { backgroundColor: '#4ade80' },
  mintedOut: {
    background:
      'linear-gradient(222.36deg, rgba(255, 66, 66, 0.8) 25.48%, rgba(255, 199, 0, 0) 229.04%)',
  },
}

const MintStatusChip = ({ mintStatus, mintedOutTime }: Props) => {
  const mintedOutDate = useMemo(
    () => (mintedOutTime !== undefined ? new Date(mintedOutTime) : undefined),
    [mintedOutTime],
  )

  return (
    <div className="flex items-center gap-x-2 bg-white/20 py-1 pr-4 pl-3 rounded-full backdrop-blur-sm text-sm">
      <div
        className={classnames(
          'rounded-full w-[0.625rem] h-[0.625rem]',
          '-ml-[0.2rem] mr-[0.2rem]',
          mintStatus === 'mintingNow' && 'minting-now-pulse',
          mintStatus === 'noMints' &&
            'ring-1 ring-inset ring-black dark:ring-white',
        )}
        style={mintStatusColor[mintStatus]}
      />

      {mintStatus === 'notAvailable' && 'Minting not available'}
      {mintStatus === 'noMints' && 'No mints yet'}
      {mintStatus === 'mintingNow' && 'Minting now'}
      {mintStatus === 'mintedOut' && (
        <span>
          Minted out{' '}
          {mintedOutDate !== undefined && (
            <HoverTimestamp timestamp={mintedOutDate} />
          )}
        </span>
      )}
    </div>
  )
}

export default React.memo(MintStatusChip)
