import { SVGAttributes } from 'react'

export default function ProjectSettingsCog(
  props: SVGAttributes<SVGSVGElement>,
) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M9.5 2.75a.75.75 0 01.75-.75h3.5a.75.75 0 01.75.75V4.5h-.625a.625.625 0 01-.625-.625.625.625 0 00-.625-.625h-1.25a.625.625 0 00-.625.625c0 .345-.28.625-.625.625H9.5V2.75zM7 4.5h2.5V5a.75.75 0 01-.75.75h-1A.75.75 0 017 5v-.5zM4.5 4.5V4a.75.75 0 01.75-.75h1A.75.75 0 017 4v.5H4.5zM4.5 7H4a.75.75 0 01-.75-.75v-1A.75.75 0 014 4.5h.5V7zM4.5 9.5V7H5a.75.75 0 01.75.75v1A.75.75 0 015 9.5h-.5zM4.5 14.5H2.75a.75.75 0 01-.75-.75v-3.5a.75.75 0 01.75-.75H4.5v.625c0 .345-.28.625-.625.625a.625.625 0 00-.625.625v1.25c0 .345.28.625.625.625s.625.28.625.625v.625zM5.75 15.25A.75.75 0 005 14.5h-.5V17H4a.75.75 0 00-.75.75v1c0 .414.336.75.75.75h.5v.5c0 .414.336.75.75.75h1A.75.75 0 007 20v-.5h2.5v1.75c0 .414.336.75.75.75h3.5a.75.75 0 00.75-.75V19.5H17v.5c0 .414.336.75.75.75h1a.75.75 0 00.75-.75v-.5h.5a.75.75 0 00.75-.75v-1A.75.75 0 0020 17h-.5v-2.5h1.75a.75.75 0 00.75-.75v-3.5a.75.75 0 00-.75-.75H19.5V7h.5a.75.75 0 00.75-.75v-1A.75.75 0 0020 4.5h-.5V4a.75.75 0 00-.75-.75h-1A.75.75 0 0017 4v.5h-2.5V5c0 .414.336.75.75.75h1A.75.75 0 0017 5v-.5h2.5V7H19a.75.75 0 00-.75.75v1c0 .414.336.75.75.75h.5v.625c0 .345.28.625.625.625s.625.28.625.625v1.25c0 .345-.28.625-.625.625a.625.625 0 00-.625.625v.625H19a.75.75 0 00-.75.75v1c0 .414.336.75.75.75h.5v2.5H17V19a.75.75 0 00-.75-.75h-1a.75.75 0 00-.75.75v.5h-.625a.625.625 0 00-.625.625c0 .345-.28.625-.625.625h-1.25a.625.625 0 01-.625-.625.625.625 0 00-.625-.625H9.5V19a.75.75 0 00-.75-.75h-1A.75.75 0 007 19v.5H4.5V17H5a.75.75 0 00.75-.75v-1z"
      ></path>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M9.5 8.25H9a.75.75 0 00-.75.75v.5h-.5a.75.75 0 00-.75.75v3.5c0 .414.336.75.75.75h.5v.5c0 .414.336.75.75.75h.5v.5c0 .414.336.75.75.75h3.5a.75.75 0 00.75-.75v-.5h.5a.75.75 0 00.75-.75v-.5h.5a.75.75 0 00.75-.75v-3.5a.75.75 0 00-.75-.75h-.5V9a.75.75 0 00-.75-.75h-.5v-.5a.75.75 0 00-.75-.75h-3.5a.75.75 0 00-.75.75v.5zm5 0v.5c0 .414.336.75.75.75h.5v5h-.5a.75.75 0 00-.75.75v.5h-5v-.5a.75.75 0 00-.75-.75h-.5v-5h.5a.75.75 0 00.75-.75v-.5h5z"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}
