import { SVGAttributes } from 'react'

export default function MinusIcon(props: SVGAttributes<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M20.125 10c.345 0 .625.28.625.625s.28.625.625.625.625.28.625.625v1.25c0 .345-.28.625-.625.625a.625.625 0 00-.625.625c0 .345-.28.625-.625.625H3.875a.625.625 0 01-.625-.625.625.625 0 00-.625-.625.625.625 0 01-.625-.625v-1.25c0-.345.28-.625.625-.625s.625-.28.625-.625.28-.625.625-.625h16.25z"
      ></path>
    </svg>
  )
}
