import { SVGAttributes } from 'react'

export default function InfoIcon(props: SVGAttributes<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M15 22a.75.75 0 00.75-.75v-.5h-7.5v.5c0 .414.336.75.75.75h6zM5.75 19.5v.5c0 .414.336.75.75.75h1.75v-.5a.75.75 0 00-.75-.75H5.75zM4.5 18.25v.5c0 .414.336.75.75.75h.5V19a.75.75 0 00-.75-.75h-.5zM3.25 15.75v1.75c0 .414.336.75.75.75h.5V16.5a.75.75 0 00-.75-.75h-.5zM3.25 8.25h-.5A.75.75 0 002 9v6c0 .414.336.75.75.75h.5v-7.5zM4.5 5.75H4a.75.75 0 00-.75.75v1.75h.5a.75.75 0 00.75-.75V5.75zM5.75 4.5h-.5a.75.75 0 00-.75.75v.5H5A.75.75 0 005.75 5v-.5zM8.25 3.25H6.5a.75.75 0 00-.75.75v.5H7.5a.75.75 0 00.75-.75v-.5zM8.25 3.25v-.5A.75.75 0 019 2h6a.75.75 0 01.75.75v.5h1.75a.75.75 0 01.75.75v.5h.5a.75.75 0 01.75.75v.5h.5a.75.75 0 01.75.75v1.75h.5A.75.75 0 0122 9v6a.75.75 0 01-.75.75h-.5v1.75a.75.75 0 01-.75.75h-.5v.5a.75.75 0 01-.75.75h-.5v.5a.75.75 0 01-.75.75h-1.75v-.5a.75.75 0 01.75-.75h1.75V19a.75.75 0 01.75-.75h.5V16.5a.75.75 0 01.75-.75h.5v-7.5h-.5a.75.75 0 01-.75-.75V5.75H19a.75.75 0 01-.75-.75v-.5H16.5a.75.75 0 01-.75-.75v-.5h-7.5z"
      ></path>
      <path
        fill="currentColor"
        d="M10.25 18.25a.75.75 0 01-.75-.75v-7.25a.75.75 0 01.75-.75h3.5a.75.75 0 01.75.75v7.25a.75.75 0 01-.75.75h-3.5zM13.75 8.25a.75.75 0 00.75-.75v-1a.75.75 0 00-.75-.75h-3.5a.75.75 0 00-.75.75v1c0 .414.336.75.75.75h3.5z"
      ></path>
    </svg>
  )
}
