import { ReactElement, useEffect, useRef, useState } from 'react'
import { AnimatePresence, LayoutGroup, motion } from 'framer-motion'
import uniqBy from 'lodash/uniqBy'

type Card = {
  blockNumber: string
  logIndex: number
  element: ReactElement
}

type Props = {
  cards: Card[]
  numCards: number
}

const makeKey = (card: Card) => `${card.blockNumber}:${card.logIndex}`

export const CardRow = ({ cards: nextCards, numCards }: Props) => {
  const [cards, cardsSet] = useState(nextCards)
  const cardsRef = useRef(nextCards)
  const [queuedCards, queuedCardsSet] = useState<Card[]>([])
  const [renderNextCard, renderNextCardSet] = useState(true)

  // Framer Motion supports enter/exit animations via `AnimatePresence` and it
  // supports staggering child animations via `staggerChildren`, but these
  // don't work together - there is no staggering children when they are added
  // or removed.
  //
  // We work around this by queuing new children and append them one at a time
  // to the end of the list of cards to display.

  // This effect controls adding new cards to the animation queue. It re-runs
  // each time we pass new children, and does a quick diff based on most recent
  // queued or rendered card. We track the current rendered cards in a ref to
  // avoid timing issues with React updates.
  useEffect(() => {
    queuedCardsSet((queuedCards) => {
      const mostRecentCard = [...queuedCards, ...cardsRef.current][0]
      const newCards =
        mostRecentCard !== undefined
          ? nextCards.filter(
              (card) =>
                BigInt(card.blockNumber) > BigInt(mostRecentCard.blockNumber) ||
                (BigInt(card.blockNumber) ===
                  BigInt(mostRecentCard.blockNumber) &&
                  card.logIndex > mostRecentCard.logIndex),
            )
          : nextCards
      return [...newCards, ...queuedCards]
    })
  }, [nextCards])

  // This effect pulls the right-most (oldest) item off the queue and adds it
  // to the front of the rendered list of cards (now newest), and updates
  // state and ref objects accordingly. The ref is updated inside the atomic
  // setter to ensure that they get updated at the same time and without extra
  // data dependencies in the effect.
  useEffect(() => {
    const nextCard = queuedCards[queuedCards.length - 1]
    if (nextCard !== undefined && renderNextCard) {
      queuedCardsSet((queuedCards) => queuedCards.slice(0, -1))
      cardsSet((cards) => {
        const nextCards = uniqBy([nextCard, ...cards], makeKey).slice(
          0,
          numCards,
        )
        cardsRef.current = nextCards
        return nextCards
      })
      // limit the frequency at which new cards can be rendered
      renderNextCardSet(false)
      setTimeout(() => renderNextCardSet(true), 200)
    }
  }, [queuedCards, renderNextCard, numCards])

  return (
    <LayoutGroup>
      <AnimatePresence initial={false}>
        {cards.slice(0, numCards).map((card, i) => (
          <motion.div
            key={makeKey(card)}
            style={{
              gridColumnStart: i + 1,
              gridRowStart: 1,
            }}
            layout="position"
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0 }}
            transition={{
              duration: 0.2,
              layout: { duration: 0.2 },
            }}
          >
            {card.element}
          </motion.div>
        ))}
      </AnimatePresence>
    </LayoutGroup>
  )
}
