import { SVGAttributes } from 'react'

export default function PlusIcon(props: SVGAttributes<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M10.75 2.625c0-.345.28-.625.625-.625h1.25c.345 0 .625.28.625.625s.28.625.625.625.625.28.625.625V8.75c0 .414.336.75.75.75h4.875c.345 0 .625.28.625.625s.28.625.625.625.625.28.625.625v1.25c0 .345-.28.625-.625.625a.625.625 0 00-.625.625c0 .345-.28.625-.625.625H15.25a.75.75 0 00-.75.75v4.875c0 .345-.28.625-.625.625a.625.625 0 00-.625.625c0 .345-.28.625-.625.625h-1.25a.625.625 0 01-.625-.625.625.625 0 00-.625-.625.625.625 0 01-.625-.625V15.25a.75.75 0 00-.75-.75H3.875a.625.625 0 01-.625-.625.625.625 0 00-.625-.625.625.625 0 01-.625-.625v-1.25c0-.345.28-.625.625-.625s.625-.28.625-.625.28-.625.625-.625H8.75a.75.75 0 00.75-.75V3.875c0-.345.28-.625.625-.625s.625-.28.625-.625z"
      ></path>
    </svg>
  )
}
