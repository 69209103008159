import React from 'react'
import HoverCard from 'components/HoverCard'
import classnames from 'utils/classnames'
import { s } from 'utils/helpers'

export const background = 'bg-neutral-300 dark:bg-neutral-700'

type Props = {
  total: number
  wallets: number
  size?: 'small' | 'large'
}

function PendingTransactionsChip({ total, wallets, size = 'large' }: Props) {
  return (
    <HoverCard
      trigger={
        <div
          className={classnames(
            background,
            'flex items-center justify-center',
            'text-xs md:text-base',
            'rounded-md',
            'min-w-6 md:min-w-7',
            'anim-border',
            size === 'small' &&
              'min-w-0 w-[1.65rem] h-[1.65rem] text-[12px] md:text-[12px]',
          )}
        >
          <div
            className={classnames(
              background,
              'mx-0.5 text-center rounded-md',
              size === 'large' && 'min-w-7 my-0.5',
              size === 'small' && 'min-w-6 my-[1.5rem]',
            )}
          >
            {wallets}
          </div>
        </div>
      }
      content={
        <div className="text-sm">
          {`${total} pending transaction${s(total)} from ${wallets} wallet${s(
            wallets,
          )}`}
        </div>
      }
    />
  )
}

export default React.memo(PendingTransactionsChip)
