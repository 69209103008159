import React from 'react'

type Props = {
  visibleContent(): React.ReactNode
  hiddenContent(): React.ReactNode
}

function HoverReveal({ visibleContent, hiddenContent }: Props) {
  return (
    <span className="group inline-grid grid-cols-1 grid-rows-1">
      <span className="col-start-1 row-start-1 flex group-hover:hidden">
        {visibleContent()}
      </span>
      <span className="col-start-1 row-start-1 hidden group-hover:flex">
        {hiddenContent()}
      </span>
    </span>
  )
}

export default React.memo(HoverReveal)
