import React from 'react'
import { formatTimestamp } from 'utils/time'
import HoverReveal from './HoverReveal'

type Props = {
  timestamp: Date
}

function HoverTimestamp({ timestamp }: Props) {
  return (
    <HoverReveal
      visibleContent={() => formatTimestamp(timestamp)}
      hiddenContent={() => timestamp.toLocaleString()}
    />
  )
}

export default React.memo(HoverTimestamp)
