import { SVGProps } from 'react'

export default function CustomizeWand(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M18.666 4.111h-1.11v-.555a.556.556 0 011.11 0v.555zM18.666 5.222h.556a.556.556 0 000-1.11h-.556v1.11z"
      ></path>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M17.555 6.333H18a.667.667 0 00.666-.666v-.445h-1.11v-1.11h-.445a.667.667 0 00-.667.666v.444H16a.667.667 0 00-.667.667v.444h-.444a.667.667 0 00-.667.667v.444h-.556a.556.556 0 00-.555.556.556.556 0 01-.556.556.556.556 0 00-.555.555.556.556 0 01-.556.556.556.556 0 00-.555.555.556.556 0 01-.556.556.556.556 0 00-.555.555.556.556 0 01-.556.556.556.556 0 00-.555.556.556.556 0 01-.556.555.556.556 0 00-.556.556.556.556 0 01-.555.555.556.556 0 00-.556.556.556.556 0 01-.555.555.556.556 0 00-.556.556.556.556 0 01-.555.555.556.556 0 00-.556.556.556.556 0 01-.555.556.556.556 0 00-.556.555.556.556 0 01-.556.556.556.556 0 00-.555.555v1c0 .368.298.667.667.667h1a.556.556 0 00.555-.556c0-.306.249-.555.556-.555a.556.556 0 00.555-.556c0-.307.249-.555.556-.555A.556.556 0 006.444 18c0-.307.25-.555.556-.555a.556.556 0 00.555-.556c0-.307.25-.556.556-.556a.556.556 0 00.556-.555c0-.307.248-.556.555-.556a.556.556 0 00.556-.555c0-.307.248-.556.555-.556a.556.556 0 00.556-.555c0-.307.248-.556.555-.556a.556.556 0 00.556-.556c0-.306.249-.555.555-.555a.556.556 0 00.556-.556c0-.306.249-.555.555-.555a.556.556 0 00.556-.556c0-.307.249-.555.556-.555a.556.556 0 00.555-.556v-.555h.445a.667.667 0 00.666-.667v-.445h.445a.667.667 0 00.666-.666v-.445zm-1.11 0h-1.112v1.111h-1.11v.445c0 .368.298.667.666.667h.444V7.444h1.111v-1.11zm0 0h1.11v-1.11h-1.11v1.11zM7.555 5.222H7a.556.556 0 100 1.111h.555v.445c0 .368.299.666.667.666h.445V8a.556.556 0 001.11 0v-.556h.445a.667.667 0 00.667-.666v-.445h.555a.556.556 0 000-1.11h-.555v-.445a.667.667 0 00-.667-.667h-.444v-.555a.556.556 0 00-1.111 0v.555h-.445a.667.667 0 00-.667.667v.444zm0 0H8a.667.667 0 00.667-.666V4.11h1.11v.445c0 .368.3.666.667.666h.445v1.111h-.445A.667.667 0 009.778 7v.444H8.667V7A.667.667 0 008 6.333h-.445v-1.11z"
        clipRule="evenodd"
      ></path>
      <path
        fill="currentColor"
        d="M22 9.111a.556.556 0 00-.556-.555A.556.556 0 0120.89 8a.556.556 0 00-1.111 0 .556.556 0 01-.556.556.556.556 0 000 1.11c.307 0 .556.25.556.556a.556.556 0 101.11 0c0-.307.25-.555.556-.555A.556.556 0 0022 9.11z"
      ></path>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M17.555 12.444a.556.556 0 10-1.11 0v1.667h-1.667a.556.556 0 100 1.111h1.666v1.667a.556.556 0 101.111 0v-1.667h1.667a.556.556 0 000-1.11h-1.667v-1.668zm0 1.667v1.111h-1.11v-1.11h1.11z"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}
